<template>
  <v-list-group :value="false">
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title style="font-weight: bold">
          {{ listTitle }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <v-list-item v-for="listItem in listArray" :key="listItem.id">
      <v-list-item-title v-if="descriptionMode">
        {{ listItem.description }}
      </v-list-item-title>
      <v-list-item-title v-if="phoneNumberMode">
        {{ listItem.phoneNumber }}
      </v-list-item-title>
      <v-list-item-action>
        <v-btn
          v-if="!newVacancyCreation"
          icon
          @click="$emit('list-item-delete', listItem.id)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-checkbox
          v-if="newVacancyCreation"
          :value="checkboxState(listItem.id)"
          @click="$emit('list-item-change-array', checkedArray, listItem.id)"
        />
      </v-list-item-action>
    </v-list-item>
    <v-list-item v-if="!newVacancyCreation">
      <v-text-field
        v-if="descriptionMode"
        v-model="newListItem"
        dense
        label="Описание"
      />
      <v-text-field
        v-if="phoneNumberMode"
        v-model="newListItem"
        v-mask="'+7##########'"
        label="Телефон"
        dense
        :rules="[$validate.phoneMin]"
      />
      <v-list-item-action>
        <v-btn
          icon
          @click="
            $emit('list-item-add', newListItem);
            newListItem = '';
          "
        >
          <v-icon>mdi-plus-outline</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-list-group>
</template>

<script>
export default {
  props: {
    listTitle: {
      type: String,
      required: true,
    },
    listArray: {
      type: Array,
      required: true,
    },
    phoneNumberMode: {
      type: Boolean,
    },
    descriptionMode: {
      type: Boolean,
    },
    newVacancyCreation: {
      type: Boolean,
    },
    checkedArray: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      newListItem: "",
    };
  },
  methods: {
    checkboxState(id) {
      let indexOfId = this.checkedArray.indexOf(id);
      if (indexOfId != -1) {
        return true;
      } else return false;
    },
  },
};
</script>
